<template>
  <i
    style="padding-left: 2px; cursor: pointer"
    class="el-icon-copy-document fortress-copy"
    :data-clipboard-text="copyData"
    @click="copy($event)"
  ></i>
  <!-- <el-button type="success" icon="el-icon-copy-document" circle></el-button> -->
</template>
<script>
import Clipboard from "clipboard";
export default {
  name: "FortressCopy",
  props: {
    copyData: [Number, String],
  },
  mounted() {
    this.clipBoard = new Clipboard(".fortress-copy");
  },
  methods: {
    copy() {
      this.$message({
        type: "success",
        message: "复制成功!",
      });
    },
  },
};
</script>
